.digi-username-container {
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 10px;
  border-radius: 8px;
  font-size: 18px;
}

.digi-user-img {
  width: 30px;
  border-radius: 24px;
  margin-right: 5px;
  cursor: pointer;
}

.digi-logout-icon {
  cursor: pointer;
  color: white;
  font-size: 14px;
  font-weight: 500;
}

.digi-logout-icon:hover {
  color: red;
}
