/* .email-editor-container {
    position: relative;
  } */

/* .email-editor-toolbar {
    position: absolute;
    bottom: 0;
  }
  .email-editor .rdw-image-modal {
    top: auto !important;
    bottom: calc(100% + 10px) !important;
  }
  .email-editor-wrapper {
    margin-bottom: 40px;
  }
  .public-DraftStyleDefault-block {
    margin: 0px;
  }
  .email-editor-content {
    min-height: 200px;
  } */

/* ////---------------------------------------------------------- */
.email-editor-toolbar {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}
.rdw-editor-main {
  padding: 15px 30px;
}
.public-DraftStyleDefault-block {
  margin: 0px;
}
/* ----------------------------------------------- */
.mail_card {
  background-color: #f5f7f9 !important;
  position: relative;
}
.mail_card_head_1 {
  padding: 6px 18px !important;
}
.mail_card_head_2 {
  padding: 6px 18px !important;
}
.mail_card_Content {
  min-height: 55vh;
  max-height: 55vh;
  position: relative;
  background-color: white;
  overflow-y: auto;
  padding: 0px !important;
}
.mail_card_action {
  height: 40px;
}
.email_to {
  font-size: 16px;
}
.email_send_btn {
  font-size: 14px;
  padding: 4px 15px 5px;
  border: none;
  border-radius: 4px;
  outline: 0;
  color: #fff;
  min-width: 80px;
  background-color: #264966;
  border: 1px solid #12344d;
  background-image: linear-gradient(to bottom, #264966, #12344d);
}
.email_delete_btn {
  height: 32px;
  min-width: 32px;
  border-radius: 4px;
  border: 1px solid #cfd7df;
  color: #12344d;
  cursor: pointer;
  padding-top: 5px;
  margin-right: 2px;
  font-size: 14px;
  vertical-align: middle;
  transition: 0.1s ease-in;
  background-image: linear-gradient(to bottom, #fff, #f3f5f7);
}
.email_delete_btn:hover {
  background-color: #f3f5f7;
}
.email_label {
  height: 25px;
  width: 25px;
  text-align: center;
  color: #5a6670;
  cursor: pointer;
}
.email_label:hover {
  background-color: #e3e6ea;
}
.email_saved_text {
  display: inline-block;
  padding-right: 10px;
  font-size: 14px;
  color: #acb6be;
}
.fa-expand {
  color: #5a6670;
}
.mail_card .ck-toolbar {
  /* border: none; */
  border-radius: 3px !important;
  border-bottom-width: 1px !important;
}
.mail_card .ck-content {
  min-height: 49vh;
  max-height: 49vh;
  padding: 10px;
  border: none !important;
}
.mail_card .ck-content:focus {
  border: none !important;
  box-shadow: none !important;
}
