.portal_table {
  border-collapse: collapse;
  width: 100%;
}
h2 {
  font-size: 25px;
  font-weight: 600;
  word-spacing: 1px;
}
.portal_table th,
.portal_table td {
  padding: 10px;
  text-align: left;
  vertical-align: middle;
}

.portal_table th {
  font-weight: bold;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
  text-transform: uppercase;
  font-size: 12px;
}

.portal_table td {
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  font-size: 14px;
}

.portal_table tr:hover td {
  background-color: #fafafa;
}

.portal_table tr:nth-child(even) td {
  background-color: #f5f5f5;
}

.portal_table td:first-child {
  border-left: 5px solid #0052cc;
}

.portal_table td:last-child {
  border-right: 5px solid #0052cc;
}

.portal_table th:first-child {
  border-top-left-radius: 5px;
}

.portal_table th:last-child {
  border-top-right-radius: 5px;
}

.actions {
  display: flex;
  align-items: center;
  gap: 10px;
}

.icon {
  font-size: 20px;
  color: #ccc;
  cursor: pointer;
}

.role-list-container {
  margin: 16px 0;
}

.role-list-title {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.role-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.role-list-item {
  display: flex;
  align-items: center;
  margin: 16px 0;
  padding: 0 0 0 20px;
}

.role-list-bullet {
  width: 8px;
  height: 8px;
  margin-right: 12px;
  border-radius: 50%;
  background-color: #6f7c87;
}

.role-list-name {
  font-size: 20px;
  font-weight: bold;
}
.icon-btn {
  width: 50px;
  height: 50px;
  border: 1px solid #cdcdcd;
  background: white;
  border-radius: 25px;
  overflow: hidden;
  position: relative;
  transition: width 0.2s ease-in-out;
  font-weight: 500;
  font-family: inherit;
  margin-left: 15px;
}

.add-btn:hover {
  width: fit-content;
  padding-left: 25px;
  padding-right: 25px;
}

.add-btn::before,
.add-btn::after {
  transition: width 0.2s ease-in-out, border-radius 0.2s ease-in-out;
  content: "";
  position: absolute;
  height: 4px;
  width: 10px;
  top: calc(50% - 2px);
  background: seagreen;
}

.add-btn::after {
  right: 14px;
  overflow: hidden;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.add-btn::before {
  left: 14px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.icon-btn:focus {
  outline: none;
}

.btn-txt {
  opacity: 0;
  transition: opacity 0.2s;
  white-space: nowrap;
}

.add-btn:hover::before,
.add-btn:hover::after {
  width: 4px;
  border-radius: 2px;
}

.add-btn:hover .btn-txt {
  opacity: 1;
}

.add-icon::after,
.add-icon::before {
  transition: all 0.2s ease-in-out;
  content: "";
  position: absolute;
  height: 20px;
  width: 2px;
  top: calc(50% - 10px);
  background: seagreen;
  overflow: hidden;
}

.add-icon::before {
  left: 22px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.add-icon::after {
  right: 22px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.add-btn:hover .add-icon::before {
  left: 15px;
  height: 4px;
  top: calc(50% - 2px);
}

.add-btn:hover .add-icon::after {
  right: 15px;
  height: 4px;
  top: calc(50% - 2px);
}

.change-button {
  color: blue;
  cursor: pointer;
}

.admin-table-pagination p {
  margin: 0 !important;
}
