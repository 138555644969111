.digi-approval-container {
  display: flex;
  flex-direction: column;
}

.digi-warning-icon {
  width: 30px;
  margin-right: 10px;
}

.digi-approve-admin {
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.87);
}

.digi-approve-email {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.54);
  margin-left: 35px;
  margin-top: 10px;
}

.w-auto {
  width: auto !important;
}

.digi-flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.digi-flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}
