.DatePicker .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100%;
  font-size: 12px;
}
.css-cwhad8-MuiDateCalendar-root {
  width: 280px !important; /* Adjust the width as needed */
  height: 300px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
