.login-page {
  background-color: #f4f5f7;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

}

.login-container {
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 12%), 0 0 1px rgb(0 0 0 / 8%);
  padding: 32px;
  width: 460px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.login-title {
  font-size: 24px;
  font-weight: 500;
  margin: 40px;
}

.google-logo {
  background-image: url("../../Assets/google.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 24px;
  width: 24px;
  margin-right: 8px;
}

.button-text {
  color: #757575;
  font-size: 14px;
  font-weight: 500;
}

.login-button {
  cursor: pointer;
  display: flex;
  border: 1px solid #2563eb;
  width: fit-content;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 1px rgba(0, 0, 0, 0.08);
}

.digi-login-page {
  display: flex;
  justify-content: space-between;
}

.digi-login-page .digi-info {
  width: 50%;
  background-image: url("../../Assets/tick.png");
  background-color: #2b68e8;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
}

.digi-login-page .header {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 60px;
  line-height: 66px;
  letter-spacing: -2px;
  color: #ffffff;
}

.digi-login-page .sub-header {
  color: #cbd5e1;
  font-weight: 500;
  font-size: 18px;
  margin-top: 10px;
}
