.image-gallery {
  display: flex;
  justify-content: space-between;
}

.gallery-image {
  width: 133px;
  height: 80px;
  object-fit: cover;
  margin-right: 10px;
}

.take-picture-webcam-attachment {
  cursor: pointer !important;
  width: 150px !important;
  margin-left: 95px !important;
  margin-top: 30px !important;
  bottom: 0.7rem !important;
}

.take-picture-webcam-cancel-attachment {
  cursor: pointer !important;
  width: 120px !important;
  margin-left: 290px !important;
  margin-top: -36px !important;
  bottom: 0.7rem !important;
}
