.registration-page {
  background-color: #f5f5f5;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.registration-container {
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 32px;
  width: 400px;
}

.registration-container h1 {
  margin-top: 0;
  margin-bottom: 24px;
  font-size: 28px;
}

.registration-page .form-group {
  margin-bottom: 16px;
}

.registration-page .form-group label {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
  color: #424242;
}

.registration-page .form-group input,
.registration-page .form-group select {
  width: 100%;
  height: 40px;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  background-color: white;
  color: #424242;
  font-size: 16px;
  font-weight: 400;
  box-shadow: none;
}

.registration-page .form-group select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23666666%"><path d="M7.41 8.59L12 13.17l4.59-4.58L18 9l-6 6-6-6z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 16px 16px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  color: #424242;
  font-size: 16px;
  font-weight: 400;
  height: 40px;
  padding: 8px 32px 8px 8px;
  width: 100%;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-shadow: none;
  transition: border-color 0.2s ease-in-out;
}

.registration-page .form-group select:focus {
  border-color: #0052cc;
  outline: none;
}

.registration-page .form-group select option {
  background-color: #f5f5f5;
  color: #424242;
}

.registration-page .form-group select option:checked {
  background-color: #0052cc;
  color: white;
}

.registration-page button[type="submit"] {
  background-color: #0052cc;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  height: 40px;
  padding: 0 16px;
  cursor: pointer;
}

.registration-page button[type="submit"]:hover {
  background-color: #004ba0;
}

.registration-page .img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
}

.camera-container {
  text-align: center;
}
.take-picture-label {
  float: left;
  margin-top: 15px;
  font-size: 14px;
  font-weight: 500;
  color: #424242;
}

.takeCamera {
  margin-left: 120px;
  border: 1px solid rgba(25, 118, 210, 0.5);
  border-radius: 4px;
  color: #1976d2;
  padding: 3px;
  width: 110px;
  cursor: pointer;
}

.takeCamera:hover {
  border: 1px solid rgba(11, 13, 145, 0.685);
}

.error {
  color: red;
  text-align: center;
  margin-top: 20px;
}

.your-picture {
  height: 40%;
  width: 40%;
}

.select-country-label {
  font-size: 14px;
}

.form-control {
  width: 337px !important;
  height: 45px;
  margin-top: 5px;
}

.div-container {
  text-align: center;
}

.first-img-label {
  margin-top: 10px;
  color: blue !important;
}

.second-img-label {
  margin-top: 20px;
  color: blue !important;
}

.third-img-label {
  margin-top: 20px;
  color: blue !important;
}

.first-img-tag {
  height: 83px;
  margin-top: 13px;
}

.send-attachment-images {
  width: 100px;
}

.add-bio-metric-heading-register {
  font-size: 25px;
}

.send-mui-icon {
  margin-left: 5px;
  height: 20px !important;
  width: 20px !important;
}

.show-one-more-pic {
  color: red;
  margin-top: 6px;
}
