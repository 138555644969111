.Forward_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px 16px 0;
  margin-bottom: 16px;
}
.Forward_head_title {
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
}
.Forward_head_title .circle {
  width: 32px;
  height: 32px;
  text-align: center;
  color: white;
  border-radius: 50%;
  background-color: #2e8df9;
}
.Forward_head_title h4 {
  font-size: 20px;
  font-weight: 700;
}
.Forward_head_icon {
  cursor: pointer;
}

/* ///--------------------------------------------> */
.Forward_container {
  /* background-color: red; */
  margin-bottom: 16px;
  margin: 16px 16px 0;
}
.Forward_container .from-email-field {
  border: 1px solid #cfd7df;
  border-bottom: none;
  border-radius: 10px 10px 0 0;
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.Forward_container .from_email_field_title {
  background-color: #f5f7f9;
  padding: 5px 10px;
  border-radius: 5px;
}
.Forward_container .email-action-field {
  padding: 8px;
  border-top: 1px solid #ebeff3;
  border: 1px solid #cfd7df;
  border-bottom: none;
}

.Forward_container .ck-toolbar {
  /* border: none; */

  border-bottom-width: 1px !important;
}
.Forward_container .ck-content {
  min-height: 30vh;
  max-height: 30vh;
  border-radius: 0px 0px 10px 10px !important;
}
.Forward_container .ck-content:focus {
  border-color: #cfd7df !important;
  box-shadow: none !important;
}
