/* body { */
/* background: linear-gradient(0.25turn, #3f87a6, #ebf8e1, #f69d3c) */
/* background-color: white;
} */

.bold {
  font-weight: bold !important;
}

.bold_normal {
  font-weight: normal;
}

.digi-green {
  color: #00b894;
}

.digi-red {
  color: #d63031 !important;
}

.digi-blue {
  color: #32abdb !important;
}

.cursor-pointer {
  cursor: pointer;
}

.nav-link {
  color: white !important;
}

.margin_left_right_30 {
  margin-right: -30px !important;
  margin-left: 30px !important;
}

.height_100 {
  height: 100% !important;
}
.height_500px {
  height: 500px !important;
}
.swal2-container {
  z-index: 20000 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-compatibility: textfield;
}
.active {
  background-color: "red";
}


.primary {
  border: none !important;
  color: #fff !important;
  background-color: #1976d2 !important;
  padding: 10px 20px !important;
  font-size: 16px !important;
  border-radius: 5px !important;
  cursor: pointer !important;
  transition: background-color 0.3s ease-in-out !important;
}

/* ---------------------------------------------------- */
