.auth-wrapper {
  width: 100%;
  height: 100%;
}
.my-modal .modal-content {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}

.savebtn {
  border-radius: 10%;
  text-align: center;
  padding: auto;
  margin: auto;
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.auth-inner {
  width: auto;
  margin: 0;
  padding: 0;
  border-radius: 0px;
  transition: all 0.3s;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.popup_th {
  width: 50% !important;
}

.popup_tr {
  height: 40px;
}

.popup_select {
  width: 90% !important;
}

.text-center {
  text-decoration: underline;
}
