/* @media only screen and (min-width: 1200px) {
  .product_card{
    width: 100%;
    height: 58vh;
  }
}
@media only screen and (min-width: 576px) {
  .product_card{
    width: 100%;
    height: 46vh;
  }
}
@media only screen and (max-width: 768px) {
  .product_card{
    width: 100%;
    height: 50vh;
  }
  
}
@media only screen and (min-width: 992px) {
  .product_card{
    width: 100%;
    height: 54vh;
  }
} */


/* ---------------------------------- */
.product {
  display: flex;
  border-radius: 10px;
  overflow: hidden;
}
.product_card{
  width: 100%;
  height: 58vh;
}
.product_card_tags{
  width: 100%;  
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.product_card_Img{
  width: 100%;
  height: 20vh;
}

.product_card_title{
  text-align: center;
}
.product-title {
  padding: 0;
  margin-top: 10px;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
}
.product_card_content p{
  width: 100%;
  height: 20vh;
  overflow-y: auto;
  padding: 10px;
  text-align: justify;

}