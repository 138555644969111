.featurelable {
  display: inline-block;
  width: 150px;
  text-align: right;
  margin-left: 100px;
  margin-top: 5px;
  text-align: start;
}
/* 
.versionlable {
  display: inline-block;
  width: 95px;
  text-align: right;
  margin-left: 100px;
  margin-top: 6px;
} */

/* .filelable {
  display: inline-block;
  width: 95px;
  text-align: right;
  margin-left: 72px;
  margin-top: 6px;
} */
