.ReplySideIcon img {
  width: 30px;
  height: 30px;
  padding: 3px;
  background: #ebeff3;

  border-radius: 5px;
}
.ReplySideIcon img:hover {
    border: 1px solid #475867;
}

