.user-details-heading {
  color: blue;
  text-align: center;
}

.Cancel:hover {
  color: red;
}

.content-div {
  text-align: center;
}

.send-user-details {
  bottom: 10px;
  width: 100px;
  padding: 5px;
  height: 35px;
  margin-top: 10px;
}

.user_name {
  padding: 5px !important;
  width: 320px !important;
  height: 50px;
}

.user_number {
  padding: 5px !important;
  width: 320px !important;
  height: 50px;
}

.error {
  color: red;
}
