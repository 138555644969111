.ck-editor__editable {
    height: 70vh;
 
}

/* .ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline */
.hide{
    display: none;
}
.hide:hover{
    display: flex;
}
.ck-content p{
    margin-bottom: 0 !important;
}
