@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* user-select: none; */
  font-family: "Source Sans Pro", sans-serif;
}
/* ::-webkit-scrollbar{
  width: 10px;
  border-radius: 10px;
} */
/* ::-webkit-scrollbar-track{
  background: #fff;
}
::-webkit-scrollbar-thumb{
  background: #888;
} */
/* ::-webkit-scrollbar-thumb:hover{
  background: #555;
  transition: all 1s linear;
} */

html {
  scroll-behavior: smooth;
}
.Link {
  text-decoration: none;
  color: white;
}
body {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  background: #ebeff3 !important ;
}

.fixed_button {
  position: fixed;
  bottom: 0px;
  left: calc(92% + -15px);
  border-radius: 50%;
  height: 60px;
  z-index: 2000;
}

.helpInner {
  min-width: 380px;
  z-index: 2100;
  height: 600px;
  background-attachment: fixed;
  background-image: linear-gradient(to bottom, #458fe1 45%, #ffff 45%);
  /* overflow: hidden; */
}
.helpInnerView_card {
  transition: all 0.5s ease-in 0s;
  border-radius: 8px;
  /* overflow: hidden auto; */
  padding: 16px 18px;
  margin-right: 10px;
  background: rgb(255, 255, 255);
  box-shadow: rgba(18, 52, 77, 0.16) 0px 0px 2px 0px,
    rgba(18, 52, 77, 0.1) 0px 2px 12px 0px;
}

.helpInner .text_help {
  /* color: #2b68e8; */
  color: #fff;
  font-weight: 700;
  font-size: 18px;
}
.cursor_pointer {
  cursor: pointer;
}

.helpInnerView {
  overflow-y: scroll;
  height: 600px;
}
.helpInnerView .close_icon {
  background-color: rgba(7, 18, 40, 0.4);
  position: absolute;
  right: 16px;
  top: 16px;
  width: 32px;
  height: 32px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 8px;
  color: #fff;
  padding: 5px;
}
.helpInnerView .take_image {
  width: 100%;
  height: 40px;
  color: rgb(18, 52, 77);
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  border: 1px solid rgb(207, 215, 223);
  border-radius: 8px;
  background: linear-gradient(rgb(255, 255, 255) 0%, rgb(245, 247, 249) 99.92%);
  box-shadow: rgba(24, 50, 71, 0.05) 0px 1px 0px 0px;
  cursor: pointer;
}
.helpInnerView .upload_file {
  border-radius: 4px;
  background-color: rgb(247, 249, 250);
  border: 1px dashed rgb(172, 182, 190);
  text-align: center;
  padding: 25px 10px;
  cursor: pointer;
}

.helpInnerView::-webkit-scrollbar-thumb {
  border-radius: 5px;
}
.he {
  color: #d4eafc;
}
.helpInner .upload_file_count {
  font-size: 1rem;
  font-weight: 700;
  color: rgb(115, 117, 139);
  text-transform: uppercase;
  margin-bottom: 5px;
}
.helpInner .upload_file_container {
  border: 1px solid rgb(207, 215, 223);
  border-radius: 4px;
  margin: 10px 0px 0px;
}
.helpInner .upload_file_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 6px;
  border-bottom: 1px solid rgb(235, 239, 243);
  margin: 0px 15px;
  overflow: hidden;
  padding: 15px;
}
.helpInner .fa-trash {
  color: rgb(115, 117, 139);
  font-size: 20px;
}
.helpInner .fa-trash:hover {
  color: red;
}

.mail-reply-images img {
  width: 300px;
}

.custom-table {
  border: 1px solid black;
  border-radius: 10px;
  border-collapse: collapse;
}
.custom-table th,
.custom-table td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
  text-decoration: none;
  text-align: center;
}
.custom-table th {
  color: white;
  background-color: #0f598e;
}

.custom-hover:hover {
  padding: 2px;
}

.vertical-text {
  text-align: top; /* Align the text to the top of the cell */
}
/* ------------------------------------------------------ */
.fs_1 {
  font-size: 1px !important;
  font-weight: 600 !important;
}
.fs_2 {
  font-size: 2px !important;
  font-weight: 600 !important;
}
.fs_3 {
  font-size: 3px !important;
  font-weight: 600 !important;
}
.fs_4 {
  font-size: 4px !important;
  font-weight: 600 !important;
}
.fs_5 {
  font-size: 5px !important;
}
.fs_6 {
  font-size: 6px !important;
  font-weight: 600 !important;
}
.fs_7 {
  font-size: 7px !important;
  font-weight: 600 !important;
}
.fs_8 {
  font-size: 8px !important;
  font-weight: 600 !important;
}
.fs_9 {
  font-size: 9px !important;
  font-weight: 600 !important;
}
.fs_10 {
  font-size: 10px !important;
  font-weight: 600 !important;
}
.fs_11 {
  font-size: 11px !important;
  font-weight: 600 !important;
}
.fs_12 {
  font-size: 12px !important;
}
.fs_13 {
  font-size: 13px !important;
  font-weight: 600 !important;
}
.fs_14 {
  font-size: 14px !important;
  font-weight: 600 !important;
  letter-spacing: 1px !important;
}
.fs_15 {
  font-size: 15px !important;
  font-weight: 600 !important;
}
.fs_16 {
  font-size: 16px !important;
  font-weight: 600 !important;
}
.fs_17 {
  font-size: 17px !important;
  font-weight: 600 !important;
}
.fs_18 {
  font-size: 18px !important;
  font-weight: 600 !important;
}
.fs_19 {
  font-size: 19px !important;
  font-weight: 600 !important;
}
.fs_20 {
  font-size: 20px !important;
  font-weight: 600 !important;
}
.fs_21 {
  font-size: 21px !important;
  font-weight: 600 !important;
}
.fs_22 {
  font-size: 22px !important;
  font-weight: 600 !important;
}
.fs_23 {
  font-size: 23px !important;
  font-weight: 600 !important;
}
.fs_24 {
  font-size: 24px !important;
  font-weight: 600 !important;
}
.fs_25 {
  font-size: 25px !important;
  font-weight: 600 !important;
}
.fs_26 {
  font-size: 26px !important;
  font-weight: 600 !important;
}
.fs_27 {
  font-size: 27px !important;
  font-weight: 600 !important;
}
.fs_28 {
  font-size: 28px !important;
  font-weight: 600 !important;
}
.fs_29 {
  font-size: 29px !important;
  font-weight: 600 !important;
}
.fs_30 {
  font-size: 30px !important;
  font-weight: 600 !important;
}

.search-tickets {
  margin-left: 300px;
}

.navigate_cell:hover {
  color: #3685fc;
  cursor: pointer;
}

/* ///------------------------------------------------ */

/* AG GRID table */
.custom-ag-grid-table {
  display: grid;
  grid-template-columns: 95% 5%;
  height: 70vh;
}
/* AG GRID table */
