.FormControl
  .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding-right: 0px !important;
  width: auto !important;
  display: flex;
  column-gap: 15px;
  align-items: center;
}
.tickets__list--dropdown {
  width: 8px;
  height: 8px;
  background-color: #a0d76a;
}
.scrolable-card {
  height: 73vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.scrolable-card::-webkit-scrollbar {
  width: 5px;
}
.scrolable-card::-webkit-scrollbar-thumb {
  background-color: #d0d0d0;
  border-radius: 4px;
}

.bottom-border-shadow {
  border-bottom: 1px solid gray;
  box-shadow: 0 2px 2px -2px gray;
}
