.ReplySidePage {
  background-color: white;
  color: black;
}

.ReplySidePage::-webkit-scrollbar {
  width: 6px;
}
.ReplySidePage::-webkit-scrollbar-thumb {
  background: #b5b9bc;
  border-radius: 10px;
}
.ReplySidePage::-webkit-scrollbar-thumb:hover {
  background: #e2dbde;
}
/* //---------------------------------------------> */

.ReplySidePage .ticket_details_header {
  position: sticky;
  padding-top: 12px;
  z-index: 100;
  top: 0px;
  background-color: white;
}
.ReplySidePage .ticket_header_container {
  display: flex;
  justify-content: space-between;
  word-break: break-all;
  margin-left: 38px;
}
.ReplySidePage .ticket_header_Icon {
  width: 22px;
  height: 22px;
  color: #647a8e;
}
.ReplySidePage .ticket_header_title {
  font-size: 20px;
  font-weight: 700;
}
/* //-------------------------------------------------> */
.ReplySidePage .ticket-created-info {
  margin-top: 4px;
  margin-left: 76px;
  display: flex;
  gap: 10px;
}
.ReplySidePage .ticket-created-title {
  font-weight: 700;
}

/* //------------------------------------------------------> */
.ReplySidePage .ticket_details_wrapper {
  padding: 12px 16px 24px;
}
.ReplySidePage .ticket_Report_description {
  margin-bottom: 16px;
  border-radius: 8px;
}
.ReplySidePage .ticket_Report_item__header {
  position: relative;
  min-height: 65px;
  padding: 12px 16px 16px;
  display: flex;
  align-items: center;
  gap: 12px;
}
.ReplySidePage .ticket_Report_header_avatar {
  width: 32px;
  height: 32px;
}
.ReplySidePage .ticket_Report_header_desc-info {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.ReplySidePage .ticket_Report_header_created-time {
  color: #475867;
  font-size: 12px;
  font-weight: 100;
  font-style: italic;
}
.ReplySidePage .ticket_Report_header_sender-info {
  padding-bottom: 4px;
  display: flex;
  align-items: center;
  font-size: 14px;
  gap: 10px;
}
.ReplySidePage .ticket_Report_header_sender-info a {
  text-decoration: none;
  font-size: 16px;
  font-weight: 700;
  text-transform: capitalize;
}

/* //--------------------------------------------------------> */
.ReplySidePage .ticket_Report_item__content {
  display: flex;
  gap: 20px;
  font-weight: normal;
  padding: 0 16px 16px 22px;
}
.ReplySidePage .ticket_Report_content_icon {
  width: 16px;
  height: 16px;
  color: #647a8e;
}

/* //-------------------------------------------------------> */
.ReplySidePage .ticket_Reply_description {
  border-radius: 8px;
  margin-bottom: 16px;
  background-image: linear-gradient(#f5f7f9, #f5f7f9);
  position: relative;
  border: 1px solid #fff;
}
.ReplySidePage .ticket_Reply_item__header {
  position: relative;
  min-height: 65px;
  padding: 12px 16px 16px;
  display: flex;
  align-items: center;
  gap: 12px;
}
.ReplySidePage .ticket_Reply_item__content {
  display: flex;
  gap: 20px;
  font-weight: normal;
  padding: 0 16px 16px 22px;
}
/* //----------------------------------------------------------> */
/* -----------------------ticket_reply_bar--------------------------- */
.ReplySidePage .ticket_reply_bar {
  border-radius: 8px;
  background-color: #f5f7f9;
  list-style: none;
  text-align: left;
  padding: 10px 16px;
  display: flex;
  gap: 12px;
  width: 100%;
}

/*------------------------------------*/
.forward-reply {
  z-index: 100;
  background-color: #f8f8f8;
  border-bottom: 1px solid rgb(221, 221, 221);
  border-left: 1px solid rgb(221, 221, 221);
  border-right: 1px solid rgb(221, 221, 221);
  position: relative;
  bottom: 36px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

/* loader style */
.spinner-border {
  margin: 2.5rem 4rem;
}
.main_screen_shot {
  position: relative;
}
