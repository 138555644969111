.DBbody {
    width: 100vw;
    height: calc(100vh - 65px);
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */
    /* display: grid;
    grid-template-areas: "header header header" "main main main";
    grid-template-rows: 0px; */
    background-color: #EEF2F6;
  }
  
  .db_aside {
    grid-area: aside;
    /* box-shadow: rgba(0, 0, 0, 0.18) 0px 0px 2px; */
  }
  
  .db_main {
    grid-area: main;
    background-color: #e9f0ff;
    padding: 10px 50px;
  }
  
  /* .button-content { */
    /* height: 100%; */
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
  /* } */
  
  .dump-button {
    height: 50px;
    width: 80%;
    margin: 2px;
    padding: 2px;
    transition: all 0.4s ease;
    cursor: pointer;
  }
  
  .dump-button:hover {
    color: white;
    background-color: blue;
  }
  
  .restore-button {
    height: 50px;
    width: 80%;
    margin: 2px;
    padding: 2px;
    transition: all 0.4s ease;
    cursor: pointer;
  }
  
  .restore-button:hover {
    color: white;
    background-color: blue;
  }
  
  .main {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }
  
  .dump-heading {
    /* background-color: blue; */
    color: #2d4dbf;
  }
  
  .dump-text {
    width: 50%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .submit-button {
    height: 50px;
    width: 25%;
    margin: 2px;
    padding: 2px;
    transition: all 0.4s ease;
    cursor: pointer;
  }
  
  .submit-button:hover {
    color: white;
    background-color: blue;
  }
  /* 
  ::-webkit-input-placeholder {
    text-align: center;
  } */
  
  .Select-DB {
    margin-right: 50px;
    font-size: large;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  }
  .selected-Folder {
    margin-top: 20px;
    margin-left: 60px;
    color: #2d4dbf;
    font-family: "Courier New", Courier, monospace;
  }
  
