.HomePage {
  /* max-height: calc(100vh - 65px); */
  width: 70%;
  display: flex;
  padding: 30px;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
}
.HomePage .HomePageTitle {
  font-weight: 700;
  font-size: 26px;
}
.HomePage .Welcome_card_heading {
  color: black;
}
.HomePage .Welcome_card_paragraph {
  color: #848893;
  font-size: 18px;
  padding-left: 10px;
  padding-top: 10px;
}
.HomePage .swiper_card {
    width: 100%;
    height:50%;
}
.HomePage .recentlyCards{
    width: 35%;
    padding: 20px;
    border-radius: 10px;
    background-color:white;
  }