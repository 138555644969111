.bio-my-img {
  border: 5px;
  border-radius: 50px;
  padding: 1rem;
  min-height: 70%;
  width: 90%;
  margin-top: -45px;
}

.bio-heading {
  text-align: center;
  color: blue;
}

.Cancel {
  padding: 3px !important;
  width: 50px !important;
  display: flex;
  left: 89% !important;
  top: -44px !important;
}

.Edit {
  width: 150px !important;
  margin-top: -74px !important;
}

.img-div-tag {
  min-height: 200px;
}

.Add-attachment {
  width: 185px;
  padding: 5px;
  margin-left: 42px !important;
}
