.CreateTestCase {
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.button {
  background-color: #1565c0 !important;
  border-radius: 3px;
  border: none;
  color: #fff;
}

.button:hover {
  background-color: dodgerblue !important;
}

.createbtn:hover {
  color: #fff;
  background-color: dodgerblue !important;
}

.tabalign {
  text-align: center;
}

.ag-grid {
  height: 518px;
}

.grid-container {
  display: flex;
  /* flex-direction: column; */
  width: 100%;
  height: -webkit-fill-available;
}

.grid-container .ag-grid {
  flex: 1;
}

.grid-container .side-column-panel {
  width: 30px;
  height: -webkit-fill-available;
  border: 1px solid #babfc7;
  border-left: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  position: relative;
}

.grid-container .side-column-panel span {
  writing-mode: vertical-lr;
  padding: 10px 5px;
  cursor: pointer;
}

.auth-wrapper {
  width: 100%;
  height: 100%;
}
.my-modal .modal-content {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}

.savebtn {
  border-radius: 10%;
  text-align: center;
  padding: auto;
  margin: auto;
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.auth-inner {
  width: auto;
  margin: 0;
  padding: 0;
  border-radius: 0px;
  transition: all 0.3s;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.popup_th {
  width: 50% !important;
}

.popup_tr {
  height: 40px;
}

.popup_select {
  width: 100%;
}
.d_all_center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.height_webkit {
  height: -webkit-fill-available;
}

.ag-cell-wrapper {
  height: -webkit-fill-available;
}

.red {
  color: red;
}

.color_blue {
  color: rgb(92, 92, 230);
}

.table_select .MuiSelect-select {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 30px !important;
  padding-left: 10px;
}

.reason_select {
  width: 100%;
}
.height_20 {
  height: 20px;
}

/* CSS */
.table_button {
  height: 30px;
  background-color: #c2fbd7;
  border-radius: 100px;
  box-shadow: rgba(44, 187, 99, 0.2) 0 -25px 18px -14px inset, rgba(44, 187, 99, 0.15) 0 1px 2px, rgba(44, 187, 99, 0.15) 0 2px 4px, rgba(44, 187, 99, 0.15) 0 4px 8px, rgba(44, 187, 99, 0.15) 0 8px 16px, rgba(44, 187, 99, 0.15) 0 16px 32px;
  color: green;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: CerebriSans-Regular, -apple-system, system-ui, Roboto, sans-serif;
  padding: 7px 20px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  font-size: 16px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.table_button:hover {
  box-shadow: rgba(44, 187, 99, 0.35) 0 -25px 18px -14px inset, rgba(44, 187, 99, 0.25) 0 1px 2px, rgba(44, 187, 99, 0.25) 0 2px 4px, rgba(44, 187, 99, 0.25) 0 4px 8px, rgba(44, 187, 99, 0.25) 0 8px 16px, rgba(44, 187, 99, 0.25) 0 16px 32px;
  transform: scale(1.05) rotate(-1deg);
}

.header_table {
  background-color: gray;
}

.ag-icon.ag-icon-menu,
.ag-icon.ag-icon-filter {
  color: blue;
}

.text_box {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.glow-unset {
  flex-grow: unset !important;
}

.w-90 {
  width: 90% !important;
}

.viewCaseColumn {
  display: flex;
  flex-direction: column;
}

.viewCaseColumn .row {
  margin-top: 10px;
  margin-bottom: 10px;
}

.stepView th,
.stepView td {
  border: 1px solid black;
}

.stepView_gray {
  background-color: grey !important;
}

.pin_position {
  position: absolute;
  margin-top: -33px;
  margin-left: 250px;
}

.width275 {
  width: 275px;
}

.logoImg {
  margin: 0;
  max-height: 40px;
}