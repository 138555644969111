.update-img {
  padding: 4px !important;
  width: 146px !important;
  margin-left: 49px !important;
  margin-top: 24px;
}

.update-upload-img {
  padding: 5px !important;
  width: 147px !important;
  margin-top: 31px !important;
  left: 20% !important;
}

.update-close-img {
  padding: 5px !important;
  width: 150px !important;
  margin-top: 51px !important;
  margin-left: 50px !important;
  bottom: 1.5rem !important;
}

.upload-user-picture {
  cursor: pointer !important;
  width: 170px !important;
  margin-left: 65px !important;
  margin-top: 52px !important;
  bottom: 1.5rem !important;
}

.upload-user-picture-cancel {
  cursor: pointer !important;
  width: 100px !important;
  margin-left: 257px !important;
  margin-top: -36px !important;
  bottom: 1.5rem !important;
}

.handleCameraImage {
  height: 450px;
  width: 500px;
  transform: rotateY(170deg);
  text-align: center;
  padding: 5px;
  margin: 5px;
  border-radius: 10%;
  margin-top: -2rem;
}

.take-picture-webcam {
  cursor: pointer !important;
  width: 150px !important;
  margin-left: 116px !important;
  margin-top: 5px !important;
  bottom: 0.7rem !important;
}

.take-picture-webcam-cancel {
  cursor: pointer !important;
  width: 120px !important;
  margin-left: 294px !important;
  margin-top: -36px !important;
  bottom: 0.7rem !important;
}

.update-picture-heading {
  text-align: center;
}

.sweat-alert-img {
  height: 250px;
}
