.handleCameraImage {
  height: 500px;
  width: 550px;
  transform: rotateY(170deg);
  text-align: center;
  padding: 5px;
  margin: 5px;
  border-radius: 10%;
}

.heading {
  text-align: center;
  color: blue;
}

.take-picture-user-image {
  width: 171px !important;
  left: 168px;
  top: -30px;
}

.close-user-picture-register {
  width: 171px !important;
  left: 168px;
  top: -15px;
}
