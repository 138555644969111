.ReplyHeader {
  width: 100% !important;
  position: sticky !important;
  top: 65px !important;
  z-index: 102 !important;
  background: #f5f7f9 !important;
  color: #3f5566 !important;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px !important;
}
.ReplyHeader .Expand_icon img {
  width: 18px;
  height: 18px;
}
